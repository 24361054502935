export const emailRegEx = /[a-zA-Z0-9!#$%&'*+=?^_{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+=?^_{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9]){1,}/;
// emailPltRegEx checks for multiple comma separated emails,
// single whitespace is allowed after comma between the email
export const emailPltRegEx = /^[a-zA-Z0-9!#$%&'*+=?^_{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+=?^_{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9]){1,}(,\s?[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)*$/i;

export const validateEmail = (email) => {
    return emailRegEx.test(email);
};

export const validateEmailPlt = (email) => {
    return emailPltRegEx.test(email);
};